import { createStore } from 'vuex'

const user = localStorage.getItem('devotee');
const initialState = user ? true : false

const siteLang = localStorage.getItem('site_lang');
const initialSitelang = siteLang ? siteLang : '1'

const cartItems = localStorage.getItem('cart_items');
const initialCartItems = cartItems ? cartItems : "{}"

let initialUserData = {}
let initialUserLocation = {lat:"0.00",lng:"0.00"}
if(user){
   initialUserData = JSON.parse(user);
}

export default createStore({
  state: {
    'userLoggedin': initialState,
    'userData': initialUserData,
    'userLang': initialSitelang,
    'userCartItems' : initialCartItems,
    'userLocation': initialUserLocation,
  },
  getters: {
  },
  mutations: {
    logOutUser: state => {
      state.userLoggedin = initialState
    },
    logInUser: state => {
      state.userLoggedin = true
    },
    changeUserData:(state,payload) => {
      state.userData = payload
    },
    changeUserLang:(state,payload)=>{
      state.userLang = payload
    },
    changeUserLocation:(state,payload) => {
      state.userLocation = payload
      console.log(state.userLocation)
    },
    changeCartProducts:(state,payload)=>{
      state.userCartItems = payload
    },
  },
  actions: {
    logOutUser: context => {
      context.commit('logOutUser')
    },
    logInUser: context => {
      context.commit('logInUser')
    },
    changeUserData:(context,payload) => {
      context.commit('changeUserData',payload)
    },
    changeUserLang:(context,payload) => {
      context.commit('changeUserLang',payload)
    },
    changeUserLocation:(context,payload) => {
      context.commit('changeUserLocation',payload)
    },
    changeCartProducts:(context,payload)=> {
      context.commit('changeCartProducts',payload)
    }
  },
  modules: {
  }
})
