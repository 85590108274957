<template>
  <router-view />
</template>
<style>
/* spinner */

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3498db;
  /* Blue */
  border-right: 4px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin .9s linear infinite;
}
.loader-small {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #3498db;
  /* Blue */
  border-right: 3px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin .9s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* spinner */

.main-tit{
   color:  #FF9933;
}

.main-tit:hover{
   color:  #FF9933;
}

.card{
    border: 1px solid #EEEEEE;
}

.vgt-wrap__footer{
  padding: .3em 1em .2em 1em !important;
}

.footer__navigation{
    font-size: .7rem !important;
}

.vgt-table td .btn{
    padding: 1px 10px !important;
    font-size: small;
}
.vgt-table td {
   padding: 7px 7px !important;
   font-size: .8em;
   line-height: 1.2em;
   vertical-align: middle !important;
   background-color: #FF993311;
}

.vgt-table th {
   font-size: .8em;
   line-height: .8em;
   vertical-align: middle !important;
   color:maroon !important;
   background:  #FF993322 !important;
}

.vgt-global-search{
  background: linear-gradient(#FF993355,#FF993322) !important;
}

.vgt-wrap__footer {
  padding: .28em 2em !important;
  background: linear-gradient(#FF993311,#FF993333) !important;
  border-top: none !important;
}

.vgt-global-search__input{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.vgt-wrap__footer *{
 font-size: .92em !important;
}

.vgt-table{
  letter-spacing: -.3px;
}

.vgt-action .dropdown-item{
  font-size: .75em !important;
  padding-top:10px ;
  padding-bottom:8px ;
}

.footer__row-count__label,.footer__row-count__select{
  font-size: .8em !important;
}

.footer__navigation > button{
  font-size: .8em !important;
}

.formkit-inner{
  border-radius:0 !important;
}

.view-info-card p, .view-info-card b{
  font-size:14px;
  letter-spacing: -0.5px;
  padding-inline-start: .9em;
  padding-inline-end: .7em;
}
.view-info-card hr{
  margin-inline-start: .9em !important;
  margin-inline-end: .9em !important;
}

/* for mobile */
@media (max-width: 576px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }

  .cont-title {
    padding-left: 1em;
    padding-right: 1em
  }

  .dash-card {
    margin-left: 18px;
    margin-right: 18px;
  }
}
.btn-tiny{
  max-height: 15px;
  font-size: 11px !important;
  padding: 0px 7px 17px 7px !important;
  border-radius:1px !important;
  text-align:center ;
}

input[type="time"]{
    max-height: 25px !important;
}
.sec-title{
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #000000BB;
}
</style>
