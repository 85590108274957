import { createRouter, createWebHistory } from 'vue-router'
import { uzr } from '@/user/User';

let loguser = uzr.currentUser()
var perm = {}

if (loguser) {
    perm = loguser.perm
    perm
}

const routes = [
    {
        path: '/',
        redirect: 'home',
        component: () => import('@/layouts/DefaultLayout.vue'),
        meta: { requiresAuth: false, },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/HomePage.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/temple/:templeid',
                name: 'view-temple',
                component: () => import('@/views/ViewTemple.vue'),
                meta: { has_perm: true, },
            },
           
            {
                path: '/book-a-vazhipad-for-deity/:deityid',
                name: 'book-vazhipad',
                component: () => import('@/views/VazhipadForDeity.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/contactus',
                name: 'contact-us',
                component: () => import('@/views/ContactUs.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/deities',
                name: 'all-deities',
                component: () => import('@/views/AllDeities.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/vazhipads',
                name: 'all-vazhipads',
                component: () => import('@/views/AllVazhipads.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/temples/:mdeityid?',
                name: 'all-temples',
                component: () => import('@/views/AllTemples.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/mycart/',
                name: 'my-cart',
                component: () => import('@/views/profile/MyCart.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/myorders/',
                name: 'my-orders',
                component: () => import('@/views/profile/MyOrders.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/orderpayment/',
                name: 'order-payment',
                component: () => import('@/views/payments/OrderPayment.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/orderdetails/:order_id?',
                name: 'order-details',
                component: () => import('@/views/orders/OrderDetails.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/myprofile/',
                name: 'view-profile',
                component: () => import('@/views/profile/ViewProfile.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/edit-profile/',
                name: 'edit-profile',
                component: () => import('@/views/profile/EditProfile.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/festivals',
                name: 'all-festivals',
                component: () => import('@/views/AllFestivals.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/search/:temp_dei_key',
                name: 'search-results',
                component: () => import('@/views/TempleDeitySearchResults.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/temple-search-results/',
                name: 'temp-location-search',
                component: () => import('@/views/TempleLocationSearchResults.vue'),
                meta: { has_perm: true, },
            },
        ]
    },
    // {
    //     path: '/auth',
    //     name: 'Auth',
    //     component: () =>
    //         import('@/layouts/DefaultLayout.vue'),
    //     meta: {
    //         requiresAuth: false,
    //         has_perm: true,
    //     },
    //     children: [{
    //         path: '/login/',
    //         name: 'login',
    //         component: () => import('@/views/auth/LoginPage.vue')
    //     },
    //     ]
    // },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: { has_perm: true, },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = ('devotee');
    const hasPermission = to.matched.some(record => record.meta.has_perm)

    //console.log(authRequired);
    if (authRequired && !loggedIn) {
        const path = ''
        next('/login' + path);
    } else if (!hasPermission) {
        return next('/not-found');
    } else {
        next()
    }
})

export default router