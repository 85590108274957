
function currentUser() {
    const cuser = JSON.parse(localStorage.getItem('devotee'));
    return cuser
}

function isHyzAdm(){
    let isHyzadm = false
    const cuser = JSON.parse(localStorage.getItem('devotee'));
    if(cuser.is_hyz_admin == 1 || cuser.is_hyz_sup_admin == 1){
        isHyzadm = true
    }
    return isHyzadm
}
function isTempleAdm(){
    let isTempadm = false
    const cuser = JSON.parse(localStorage.getItem('devotee'));
    if(cuser.is_temple_admin == 1 || cuser.is_temple_sup_admin == 1){
        isTempadm = true
    }
    return isTempadm
}

export const uzr = {
    currentUser,isHyzAdm,isTempleAdm
};
